<template>
  <page-view>
    <div class="store_bind_wrapper">
      <a-tabs @change="changeQueryType" :activeKey="activeKey">
        <a-tab-pane key="0" tab="全部"></a-tab-pane>
        <a-tab-pane key="TAOBAO" tab="淘宝"></a-tab-pane>
        <!-- <a-tab-pane key="ALIBABA" tab="阿里巴巴"></a-tab-pane> -->
        <a-tab-pane key="PDD" tab="拼多多"></a-tab-pane>
        <a-tab-pane key="DOUYIN" tab="抖音"></a-tab-pane>
        <!-- <a-tab-pane key="XHS" tab="小红书"></a-tab-pane> -->
        <!-- <a-tab-pane key="KUAISHOU" tab="快手"></a-tab-pane> -->
        <!-- <a-tab-pane key="DOUYIN" tab="抖音"></a-tab-pane> -->
        <a-button
          slot="tabBarExtraContent"
          class="refresh_btn"
          @click="refreshTable"
          >点击刷新</a-button
        >
        <a-button
          slot="tabBarExtraContent"
          type="primary"
          @click="showSelectStoreModal"
          >添加店铺</a-button
        >
      </a-tabs>
      <div class="table_box">
        <a-table
          ref="table"
          rowKey="id"
          :columns="columns"
          :data-source="dataSource"
        >
          <template slot="index" slot-scope="text, record, index">{{
            index + 1
          }}</template>
          <template slot="refreshExpiresIn" slot-scope="text">
            <span>{{ formatDate(text) }}</span>
            <span v-if="text < new Date().getTime()" style="color: red"
              >授权过期</span
            >
          </template>
          <template slot="autoSend" slot-scope="text, record">
            <a-switch
              checkedChildren="开"
              unCheckedChildren="关"
              :checked="text"
              @change="handleAutoDispatch(record)"
            />
          </template>

          <template slot="opreation" slot-scope="text, record">
            <a @click="reBind(record)">重新绑定</a>
            <a class="removebind_btn" @click="cancelBind(record)">解除绑定</a>
          </template>
        </a-table>
      </div>
      <a-modal
        v-model="visible"
        title="添加店铺"
        ok-text="确定"
        cancel-text="取消"
        @ok="handleOk"
        width="780px"
      >
        <div class="bind">
          <span>选择店铺类型：</span>
          <div class="bindway">
            <div
              class="bindway_item"
              @click="setStoreType('TAOBAO2')"
              :class="storeType == 'TAOBAO2' ? 'active' : ''"
            >
              淘宝/天猫
            </div>
            <!-- <div
              class="bindway_item"
              @click="setStoreType('ALIBABA')"
              :class="storeType == 'ALIBABA' ? 'active' : ''"
            >
              阿里巴巴
            </div> -->
            <div
              class="bindway_item"
              @click="setStoreType('PDD')"
              :class="storeType == 'PDD' ? 'active' : ''"
            >
              拼多多
            </div>
            <div
              class="bindway_item"
              @click="setStoreType('DOUYIN')"
              :class="storeType == 'DOUYIN' ? 'active' : ''"
            >
              抖音
            </div>
            <!-- <div
              class="bindway_item"
              @click="setStoreType('XHS')"
              :class="storeType == 'XHS' ? 'active' : ''"
            >
              小红书
            </div> -->
            <!-- <div
              class="bindway_item"
              @click="setStoreType('KUAISHOU')"
              :class="storeType == 'KUAISHOU' ? 'active' : ''"
            >
              快手
            </div> -->
          </div>
        </div>
        <div class="link_wrapper">
          <a
            v-if="!isSdf"
            href="https://fuwu.taobao.com/ser/detail.htm?service_code=FW_GOODS-1001241799"
            class="order_link"
            target="_blank"
            >淘宝订购链接</a
          >
          <a
            class="order_link"
            v-else
            style="width: 90px; display: inline-block"
          ></a>
          <a
            href="https://fuwu.pinduoduo.com/service-market/service-detail?detailId=30607"
            class="order_link"
            target="_blank"
            >拼多多订购链接</a
          >
          <a
            href="https://fuwu.jinritemai.com/detail?from=fuwu_market_tab&service_id=211"
            class="order_link"
            target="_blank"
            >抖音订购链接</a
          >
        </div>
      </a-modal>
      <a-modal
        v-model="codeVisible"
        title="店铺码绑定"
        ok-text="绑定"
        cancel-text="取消"
        @ok="bindCode"
        @cancel="cancelCode"
        width="640px"
      >
        <p v-if="!isSdf">
          <span style="color: red">绑定操作手册：</span>
          <a
            target="_blank"
            href="https://alidocs.dingtalk.com/i/nodes/ndMj49yWjYjZgeBLFBnqYRde83pmz5aA"
            >点击查看</a
          >
        </p>
        <a-input v-model="shopCode" placeholder="请输入店铺代码" />
      </a-modal>
    </div>
  </page-view>
</template>

<script>
import {
  storeList,
  storeDelete,
  storeUpdate,
  accountGet,
  storeCodeBind
} from "@/api";
import { formatDate, arrNewSet } from "@/utils/util";
import { PageView } from "@/layouts";
// import { STable } from "@/components";

const columns = [
  {
    title: "序号",
    dataIndex: "id",
    scopedSlots: { customRender: "index" }
  },
  {
    title: "店铺名称",
    dataIndex: "shopName"
  },

  {
    title: "到期截止时间",
    dataIndex: "refreshExpiresIn",
    scopedSlots: { customRender: "refreshExpiresIn" }
  },
  {
    title: "自动发货",
    dataIndex: "autoSend",
    scopedSlots: { customRender: "autoSend" }
  },
  {
    title: "操作",
    dataIndex: "opreation",
    scopedSlots: { customRender: "opreation" }
  }
];

export default {
  components: {
    PageView
    // STable,
  },
  data() {
    return {
      activeKey: "0",
      isSdf: /sudf.cn/.test(location.href),
      columns,
      visible: false,
      codeVisible: false,
      storeType: "TAOBAO2",
      shopCode: "",
      page: { pageNum: 1, pageSize: 20, total: 0 },
      dataSource: [],
      dataClone: [],
      payUser: false,
      bindLink: {
        TAOBAO:
          "https://oauth.taobao.com/authorize?response_type=token&client_id=12443971&redirect_uri=http://wxnew.lingyunwl.net/ydwd_webpc/redirect.php&state=czkj2_lpw_" +
          window.location.origin.split("//")[1],
        "FW199-TAOBAO":
          "https://tb.fw199.com/partner/grant?appid=aXkKJqVn7tYrib0v&callback=" +
          window.location.origin +
          "/fwtaobao",
        ALIBABA:
          "https://auth.1688.com/oauth/authorize?client_id=1926419&site=1688&redirect_uri=https://auth.wodh.cn/alibaba&state=cz_lpw_" +
          window.location.origin.split("//")[1],
        DOUYIN:
          "https://fuwu.jinritemai.com/authorize?service_id=211&state=cz_lpw_" +
          window.location.origin.split("//")[1],
        PDD:
          "https://fuwu.pinduoduo.com/service-market/auth?client_id=3e6fe7aadb4748d982cc909394e03efd&redirect_uri=https%3A%2F%2Fpdd.lipw.com&response_type=code&state=cz_lpw_" +
          window.location.origin.split("//")[1]
      }
    };
  },
  created() {
    accountGet().then(res => {
      this.payUser = res.data.pay;
    });
  },
  mounted() {
    this.getStoreList();
  },

  methods: {
    arrNewSet,
    getStoreList() {
      storeList().then(res => {
        if (res.success) {
          this.dataSource = this.arrNewSet(res.data.list);
          this.dataClone = this.arrNewSet(res.data.list);
        } else {
          this.$message(res.msg);
        }
      });
    },
    changeQueryType(key) {
      this.activeKey = key;
      if (key === "0") {
        this.dataSource = JSON.parse(JSON.stringify(this.dataClone));
      } else {
        const arr = JSON.parse(JSON.stringify(this.dataClone));
        if (key === "TAOBAO") {
          this.dataSource = arr.filter(item =>
            ["TAOBAO", "FW199-TAOBAO", "TAOBAO2"].includes(item.type)
          );
        } else {
          this.dataSource = arr.filter(item => item.type === key);
        }
      }
    },
    async refreshTable() {
      const res = await storeList();
      this.$message.success("刷新成功");
      // 全部不筛选;
      if (this.activeKey === "0") {
        this.dataSource = this.arrNewSet(res.data.list);
      } else if (this.activeKey === "TAOBAO") {
        this.dataSource = this.arrNewSet(res.data.list).filter(item =>
          ["TAOBAO", "FW199-TAOBAO", "TAOBAO2"].includes(item.type)
        );
      } else {
        // 筛选
        this.dataSource = this.arrNewSet(res.data.list).filter(
          item => item.type === this.activeKey
        );
      }
    },

    setStoreType(type) {
      this.storeType = type;
    },
    formatDate,
    showSelectStoreModal() {
      this.visible = true;
    },
    handleOk() {
      // 老用户 TODO this.payUser &&  取消新老用户
      // else if (!this.payUser && this.storeType == 'TAOBAO') {
      //   window.open(this.bindLink['FW199-TAOBAO']);
      // }
      if (["TAOBAO2", "XHS", "KUAISHOU"].includes(this.storeType)) {
        this.codeVisible = true;
      } else if (this.storeType === "ALIBABA") {
        window.open(this.bindLink["ALIBABA"]);
      } else if (this.storeType === "DOUYIN") {
        window.open(this.bindLink["DOUYIN"]);
      } else if (this.storeType === "PDD") {
        window.open(this.bindLink["PDD"]);
        // this.$message.error('暂不支持绑定拼多多店铺')
      }
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    async bindCode() {
      if (!this.shopCode) return this.$message.error("请输入店铺代码");
      const res = await storeCodeBind({
        code: this.shopCode,
        type: this.storeType
      });
      if (res.success) {
        this.cancelCode();
        this.$message.success("绑定成功");
        this.getStoreList();
      } else {
        this.$message.error(res.msg);
      }
    },
    cancelCode() {
      this.codeVisible = false;
      this.shopCode = "";
    },
    handleAutoDispatch(record) {
      storeUpdate("autoSend", {
        autoSend: !record.autoSend,
        id: record.id
      }).then(res => {
        if (res.success) {
          record.autoSend = !record.autoSend;
          this.$message.success("成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    reBind(record) {
      if (record.type === "PDD") {
        // return this.$message.error('暂不支持绑定拼多多店铺')
      }
      if (/TAOBAO2|XHS|KUAISHOU/.test(record.type)) {
        this.codeVisible = true;
        this.storeType = record.type;
        return;
      }
      // record.type = record.type.replace('FW199-TAOBAO', 'TAOBAO')
      window.open(this.bindLink[record.type]);
    },
    cancelBind(record) {
      storeDelete(record.id).then(res => {
        if (res.success) {
          this.$message.success("操作成功");
          location.reload();
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.store_bind_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  font-family: PingFang SC;
}
.table_box {
  margin: 0 24px;
}
.refresh_btn {
  margin-right: 12px;
}
.removebind_btn {
  margin-left: 8px;
  color: #f93244;
}
.bind {
  display: flex;
  align-items: center;
  span {
    height: 20px;
  }
  .bindway {
    display: flex;
    .bindway_item {
      cursor: pointer;
      width: 104px;
      height: 32px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 16px;
    }
    .active {
      border: 0;
      background-image: url("~@/assets/btn_border.png");
      background-size: 100% 100%;
    }
  }
}
.link_wrapper {
  margin-top: 12px;
  margin-left: 100px;
}
.order_link {
  cursor: pointer;
  margin-right: 32px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
</style>
