import { render, staticRenderFns } from "./storeBind.vue?vue&type=template&id=4ce13a33&scoped=true&"
import script from "./storeBind.vue?vue&type=script&lang=js&"
export * from "./storeBind.vue?vue&type=script&lang=js&"
import style0 from "./storeBind.vue?vue&type=style&index=0&id=4ce13a33&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce13a33",
  null
  
)

export default component.exports